import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { edit, create, rankChange, reset, loadSeoList, loadSeoListSucess, selectProposal, clearSeoList } from './seoList.actions';


export interface SeoState{
  itemList: any[],
  status: string | 'pending' | 'loading' | 'success' | 'error',
  error: string | null,
}

export const initialState: SeoState = {
  itemList: [],
  status: 'pending',
  error: null,
};

export const seoListReducer = createReducer(
  initialState,
  on(loadSeoListSucess, (state, { seoList }) => {
    let newState =  ({
        ...state,
        status: 'success',
        itemList: seoList
    })
    return newState
  }),
  on(rankChange, (state, {rankInfo})=> {
    let existingList = [...state.itemList]

    // function arraymove(arr, fromIndex, toIndex) 
    let fromIndex = rankInfo.previousIndex
    let toIndex = rankInfo.currentIndex
    let element = existingList[fromIndex];
    existingList.splice(fromIndex, 1);
    existingList.splice(toIndex, 0, element);
    
    return ({
      ...state,
      itemList: existingList,
      status: 'success'
    })
  }),
  on(loadSeoList, (state)=> {
    return ({
      ...state,
      status: 'loading'
    })
  }),
  on(clearSeoList, (state)=> {
    return ({
      ...state,
      status: 'success',
      itemList: []
    })
  }),
  on(selectProposal, (state, { propUUID })=> {
    let filterObj = state.itemList.findIndex((item)=> item.uuid == propUUID)

    return ({
      ...state,
      itemList: [...state.itemList].map(proposal=>({...proposal})).map((newProposal, localIndex)=>{
        let localEdit = newProposal
        if(localIndex === filterObj)
        {
          //We have the selected one...
          localEdit['selected'] = true
          return localEdit
        }
        else{
          localEdit['selected'] = false
          return localEdit
        }
      })
    })
  }),
  on(edit, (state, { listItem })=> {
    let filterObj = state.itemList.findIndex((item)=> item.uuid == listItem.id)
    return ({
      ...state,
      itemList: [...state.itemList].map(item=>({...item})).map((item2, localIndex)=>{
        if(localIndex === filterObj)
        {
          let localEdit = item2
          localEdit['supl_fund'] = listItem.fundingControl
          localEdit['supl_score'] = listItem.scoreControl
          localEdit['supl_formStatus'] = listItem.formStatus
          localEdit['supl_comment'] = listItem.comment
          return localEdit
        }
        else{
          return item2
        }
      }),
      status: 'success'
    })
  })
  // on(create, (state) => {
  //   return []
  // }),
  // on(reset, (state) => [])
);