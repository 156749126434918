import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpMeComponent } from './features/help-me/help-me.component';
import { AuthGuard, AdminOnlyGuard, SeoOnlyGuard, PebOnlyGuard, TagOnlyGuard, PebTagGuard, TagAndPebGuard } from './shared/guards/authGuard';

const routes: Routes = [
  {
    path: 'auth',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => {
          return m.AuthModule
        }
      ),
  },
  {
    path: 'proposals',
    canActivate: [TagAndPebGuard],
    loadChildren: () =>
      import('./review-2/review-2.module').then(
        (m) => {
          return m.Review2Module
        }
      ),
  },
  {
    path: 'docs',
    canActivate: [PebTagGuard],
    loadChildren: () =>
      import('./docs/docs.module').then(
        (m) => {
          return m.DocsModule
        }
      ),
  },
  {
    path: 'review',
    canActivate: [PebTagGuard],
    loadChildren: () =>
      import('./review/review.module').then(
        (m) => {
          return m.ReviewModule
        }
      ),
  },
  {
    path: 'help',
    loadComponent: () => import('./features/help-me/help-me.component').then(a=>a.HelpMeComponent)
  },
  {
    path: 'settings',
    canActivate: [AdminOnlyGuard],
    loadChildren: () =>
      import('./settings/settings.module').then(
        (m) => {
          return m.SettingsModule
        }
      ),
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => {
          return m.AuthModule
        }
      ),
  },
  { path: '', redirectTo: '/home', pathMatch: "full" },
  {
    path: 'home',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => {
          return m.HomeModule
        }
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
