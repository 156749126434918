import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../app.state";
import { createAction } from "@ngrx/store";
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DocumentService } from "src/app/shared/services/document.service";
import { loadSeoList, loadSeoListSucess, loadSeoListFailure, rankChange, rankInfo, rankChangeFail, rankChangeSuccess } from "./seoList.actions";
import { catchError, exhaustMap, from, map, of, switchMap, withLatestFrom } from "rxjs";
import { DownloadService } from "src/app/shared/services/download.service";
import { selectSeoItemList } from "./seoList.selector";

// this._downloadService.downloadSuggestedOrder("_test-tag_").then(result => {
//     this.downloadedPdfs = result
//     this.loading = false
//     console.log("dd", this.downloadedPdfs)
//     console.log("dd", this.downloadedPdfs.length)

//   })

@Injectable()
export class seoListEffects{
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private docService: DocumentService,
        private downloadService: DownloadService
    ){

    }

    rankListChanged$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(rankChange),
          withLatestFrom(this.store.select(selectSeoItemList)),
          switchMap((info)=>{
            return from(this.docService.autoSaveRank(info[1])).pipe(
                map(()=>{
                    return rankChangeSuccess()
                }),catchError(err=>of(rankChangeFail()))
            )
          })
        )
    })

    // rankListChanged$ = createEffect(() =>
    //         this.actions$.pipe(
    //             ofType(loadSeoList),
    //             switchMap(()=>
    //                 {
    //                     return from(this.downloadService.autoSaveRank()).pipe(
    //                         map((list)=> {
    //                             console.log("Check list", list)
    //                             return rankChangeSuccess()
    //                         }),
    //                         catchError((error)=> of(loadSeoListFailure({error})))
    //                     )
    //                 }
    //             )
    //         )
    // )


    loadSeoList$ = createEffect(() =>
            this.actions$.pipe(
                ofType(loadSeoList),
                switchMap(()=>
                    {
                        return from(this.downloadService.downloadSuggestedOrder("_test-tag_")).pipe(
                            map((list)=> {
                                let newOrder = []

                                list.sort(function(a:any, b:any){return a.seo_rank - b.seo_rank});
                                return loadSeoListSucess({ seoList: list })
                            }),
                            catchError((error)=> of(loadSeoListFailure({error})))
                        )
                    }
                )
            )
    )

    
}