import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service'
import { environment } from 'src/environments/environment';





@Injectable({
  providedIn: 'root'
})
export class PebTagGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  pebTag = ['tag', 'peb']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.pebTag.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      this.router.navigate(['/home'])
      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class TagAndPebGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  TagOnly = ['tag']
  seoOnly = ['seo']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.TagOnly.includes(userDetails.payload['custom:safire_role'] as string) || this.seoOnly.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      this.router.navigate(['/home'])
      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class TagOnlyGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  TagOnly = ['tag']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.TagOnly.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      this.router.navigate(['/home'])
      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class PebOnlyGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  pebOnly = ['peb']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.pebOnly.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      this.router.navigate(['/home'])
      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class SeoOnlyGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  seoOnly = ['seo']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.seoOnly.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      this.router.navigate(['/home'])
      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  allowedRoles = ['seo', 'peb', 'tag', 'admin']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.allowedRoles.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      document.location.href = environment.uamUrl + 'auth/logout';
      // this.router.navigate(['/auth']); // Redirect to login if not authenticated
      window.location.href = environment.uamUrl;

      return false
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class AdminOnlyGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private router: Router,
  ) {}
  allowedRoles = ['admin']

  canActivate(): boolean {
    let userDetails = this._authService.getUserDetails()
    if(this.allowedRoles.includes(userDetails.payload['custom:safire_role'] as string)){
      return true
    }
    else{
      document.location.href = environment.uamUrl + 'auth/logout';
      // this.router.navigate(['/auth']); // Redirect to login if not authenticated
      window.location.href = environment.uamUrl;

      return false
    }
  }
}