export const environment = {
    production: false,
    useDevToken: false,
    devToken: '',
    name: 'dev',
    version: '1.0.0',
    region: 'us-gov-west-1',
    url: 'https://safire-dev.cristlgov.com/home',
    endpoint: 'https://dev-be.safire-ai.com',
    sandboxEnabled: false,
    refreshEndpoint: 'https://dev-be.cristlgov.com/auth/refreshToken',
    uamUrl: 'https://dev.cristlgov.com', 
    cookieInfo: {
      domain: '.cristlgov.com'
    },
    amplify: {
      Auth: {
        region: 'us-gov-west-1',
        userPoolId: 'us-gov-west-1_ZqVSmahc8',
        userPoolWebClientId: '3nj8hs855bisorig8aanbtn1r7',
      },
    },
  };
  