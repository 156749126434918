import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, take, map, catchError, of } from 'rxjs';
import { resolve } from '@angular-devkit/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    constructor(private http: HttpClient) { }
    private baseUrl = environment.endpoint

    getpReviewDocuments(){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/getPersonalReview`, { }).pipe(res => {
              return res
            })
          }
          catch(err)
          {
            // console.log("catch this", err)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    getSingleDoc(uuid:string){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/singleProposalGet`, { uuid: uuid }).pipe(res => {
              return res
            })
          }
          catch(err)
          {
            // console.log("catch this", err)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    submitFinalSeo(rankInfo:any[])
    {
        try{
            return this.http.post<any>(`${this.baseUrl}/review/finalSeoSubmit`, {rankInfo}).pipe(res => {
                return res
            })
        }
        catch(err)
        {
            let errSend = new Error('autoSaveRank failed')
            return new Observable<any>(subscriber => subscriber.next(of(errSend)));
        }
    }

    autoSaveRank(rankInfo:any[])
    {
        try{
            return this.http.post<any>(`${this.baseUrl}/review/autoSaveRank`, {rankInfo}).pipe(res => {
                return res
            })
        }
        catch(err)
        {
            let errSend = new Error('autoSaveRank failed')
            return new Observable<any>(subscriber => subscriber.next(of(errSend)));
        }
    }

    autoSaveSeoSection(seoAutoSaveDoc:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/autoSaveSeo`, {seoAutoSaveDoc}).pipe(res => {
                return res
            })
        }
        catch(err){
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    rankReviewDocumentsSeo(docsToSend:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/rank`, {docsToSend}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    autoSaveSection(section:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/autoSave`, {section}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    recuseProposal(proposalUUID:string){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/recuse`, {proposalUUID}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    submitFinalReview(allDocs:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/submitFinalReview`, {allDocs}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    submitGlancedReview(section:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/submitGlancedReview`, {data:section}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    reviewDocumentsPegOrTag(docsToSend:any)
    {
        try{
            return this.http.post<any>(`${this.baseUrl}/review/submitPebOrTag`, {docsToSend}).pipe(res => {
                return res
            })
        }catch(err){
            // console.log("error on docs to send rank", docsToSend)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    getExcelDownloadLink(docUUID:string, formUUID:string){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/downloadExcel`, { 
                excelUUID: formUUID,
                docUUID: docUUID
            }).pipe(res => {
              return res
            })
          }
          catch(err)
          {
            // console.log("catch this", err)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    updateReviewDocumentsSeo(docToSend:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/updatePersonalReview`, {docToSend}).pipe(res => {
                return res
            })
        }
        catch(err){
            // console.log("catch this", err)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    updateReviewDocuments(docToSend:any){
        try{
            return this.http.post<any>(`${this.baseUrl}/review/submitPebOrTag`, {docToSend}).pipe(res => {
                return res
            })
        }
        catch(err){
            // console.log("catch this", err)
            return new Observable<any>(subscriber => subscriber.next());
        }
    }

    getWordWeights(){
        return this.http.get(`${this.baseUrl}/uploads/getWeights`, {
            observe: 'response'
        }).pipe(
            take(1)
        )
    }

    submitWordChanges(allValues:any){
        // return new Promise((resolve, reject) => {
        //     let suby = this.http.post<any>(`${this.baseUrl}/uploads/weightChanges`, { allValues: allValues }).subscribe(res => {
        //         try {
        //             suby.unsubscribe()
        //             resolve(res.response)
        //         }
        //         catch (err) {
        //         }
        //     }, error => {
        //         reject(error)
        //     })
        // })
        try{
            // console.log("All values on api", allValues)
            return this.http.post<any>(`${this.baseUrl}/uploads/weightChanges`, { "allValues": allValues })
            .pipe(
                catchError((error: any, caught: Observable<any>): Observable<any> => {
                    // console.error('There was an error!', error);
        
                    // after handling error, return a new observable 
                    // that doesn't emit any values and completes
                    return of();
                }
            ))
            // let obj = this.http.post<any>(`${this.baseUrl}/uploads/weightChanges`, { "allValues": allValues }).pipe(res => {
            //     console.log("try res1")
            //     console.log("try res2", res)
            //     return res
            // })
            // return obj
        }
        catch(err){
            // console.log("catch this on api", err)
            return new Observable<any>(subscriber => subscriber.next());

        }
    }

    getDocByUUID(uuid: string) {
        let header = new HttpHeaders
        let params = new HttpParams().set("tag", uuid)
        params.append("uuid", uuid)



        return this.http.get(`${this.baseUrl}/uploads/getWordCount`, {
            params: {
                uuid: uuid
            },
            observe: 'response'
        }).pipe(
            take(1)
        )
    }

    getComment(fileUUID: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let suby = this.http.post<any>(`${this.baseUrl}/uploads/getcomment`, { fileUUID: fileUUID }).subscribe(res => {
                try {
                    suby.unsubscribe()
                    resolve(res.response)
                }
                catch (err) {
                }
            }, error => {
                reject(error)
            })
        })
    }

    saveComment(fileUUID: string, comment: string | null) {
        return new Promise((resolve, reject) => {
            let suby = this.http.post<any>(`${this.baseUrl}/uploads/comment`, { fileUUID: fileUUID, comment: comment }).subscribe(res => {
                try {
                    suby.unsubscribe()
                    resolve(res.response)
                }
                catch (err) {
                }
            }, error => {
                reject(error)
            })
        })
    }

    getPebs(): Promise<Array<any>>{
        return new Promise((resolve, reject)=>{
            let subscriptionPeb = this.http.post<any>(`${this.baseUrl}/search/pebs`, {}).subscribe(res => {
                try {
                    subscriptionPeb.unsubscribe()
                    resolve(res.response)
                }
                catch (err) {
                }
            }, error => {
                reject(error)
            })
        })
    }

    submitDocumentReview(uuid: string, suggestedScore: string, passValue: string, comment: string, peb: string) {
        return new Promise((resolve, reject) => {
            let suby = this.http.post<any>(`${this.baseUrl}/uploads/submit`, {
                uuid: uuid,
                passValue: passValue,
                comment: comment,
                suggestedScore: suggestedScore,
                peb: peb
            }).subscribe(res => {
                try {
                    suby.unsubscribe()
                    resolve(res.response)
                }
                catch (err) {
                }
            }, error => {
                reject(error)
            })
        })
    }

    searchDocs(searchString: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let suby = this.http.post<any>(`${this.baseUrl}/search/searchdocs`, {
                searchPhrase: searchString
            }).subscribe(res => {
                try {
                    suby.unsubscribe()
                    resolve(res.response)
                }
                catch (err) {
                }
            }, error => {
                reject(error)
            })
        })
    }


}