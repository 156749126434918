import { createSelector } from '@ngrx/store'
import { AppState } from '../../app.state'
import { SeoState } from './seoList.reducer';


export const selectSeoSlice = (state: AppState) => state.seoSlice;

export const selectSeoItemList = createSelector(
    selectSeoSlice,
    (state: SeoState) => {
        return state.itemList
    }
)