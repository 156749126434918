<div class="full-height main-holder no-scroll">
    <mat-drawer-container class="menu-container full-height" [hasBackdrop]="true">
        <mat-drawer #drawer mode="over" class="burger-menu">
            <!-- <div class="text-center align-middle flex h-screen justify-center items-center"> -->
            <div class="text-center align-middle menu-global-parent">
                <!-- <div class="grid-rows-6">
                    <div>
                        one
                    </div>
                    <div>
                        two
                    </div>
                </div> -->
                <a routerLink="/" routerLinkActive="active" (click)="closeDrawer()">
                    <div class="top-nav menu-holder">
                        <p class="menu-item">
                            Safire
                        </p>
                    </div>
                </a>
                <div class="grid grid-flow-row auto-rows-maxr">
                    @if(safire_role === 'admin')
                    {
                        <a routerLink="docs/uploads" routerLinkActive="active" (click)="closeDrawer()">
                            <div class="menu-option menu-holder">
                                <div class="menu-item">
                                    <span class="material-symbols-outlined">
                                        upload_file
                                    </span>
                                    <p class="">
                                        Uploads
                                    </p>
                                </div>
                            </div>
                        </a>
                    }
                    @if(safire_role === 'seo' || safire_role === 'tag')
                    {
                        <a routerLink="proposals/" routerLinkActive="active" (click)="closeDrawer()">
                            <div class="menu-option menu-holder">
                                <div class="menu-item">
                                    <span class="material-symbols-outlined">
                                        article
                                    </span>
                                    <p class="">
                                        Quick Proposal Scoring
                                    </p>
                                </div>
                            </div>
                        </a>
                    }
                    @if(safire_role === 'peb')
                    {
                        <a routerLink="docs/" routerLinkActive="active" (click)="closeDrawer()">
                            <div class="menu-option menu-holder">
                                <div class="menu-item">
                                    <span class="material-symbols-outlined">
                                        article
                                    </span>
                                    <p class="">
                                        Proposal Analysis and Scoring
                                    </p>
                                </div>
                            </div>
                        </a>
                    }
                    <!-- <a routerLink="review/" routerLinkActive="active" (click)="closeDrawer()">
                        <div class="menu-option menu-holder">
                            <div class="menu-item">
                                <span class="material-symbols-outlined">
                                    reviews
                                </span>
                                <p class="">
                                    Step 2: Final proposal review
                                </p>
                            </div>
                        </div>
                    </a> -->
                    @if(safire_role === 'admin')
                    {
                        <a routerLink="docs/settings" routerLinkActive="active" (click)="closeDrawer()">
                            <div class="menu-option menu-holder">
                                <div class="menu-item">
                                    <span class="material-symbols-outlined">
                                        settings
                                    </span>
                                    <p class="">
                                        Ranking settings
                                    </p>
                                </div>
                            </div>
                        </a>
                    }
                    <a routerLink="help" routerLinkActive="active" (click)="closeDrawer()">
                        <div class="menu-option menu-holder">
                            <div class="menu-item">
                                <span class="material-symbols-outlined">
                                    help
                                </span>
                                <p class="">
                                    help
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content class="no-scroll mainContent">
            
            <!-- <button mat-raised-button (click)="drawer.toggle()">Toggle Menu</button> -->
            <app-toolbar (onMenuClicked)=onMenuClicked($event)>
            </app-toolbar>
            <!-- <div> -->
                <router-outlet class="app-doc-home"></router-outlet>
            <!-- </div> -->
        </mat-drawer-content>
    </mat-drawer-container>
</div>

