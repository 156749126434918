import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { resolve } from '@angular-devkit/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private http: HttpClient) { }
  private baseUrl = environment.endpoint

  downloadSuggestedOrder(tag:any):Promise<any>{
    return new Promise((resolve, reject)=>{
        try{
            let header = new HttpHeaders
            let params = new HttpParams().set("tag",tag)
            params.append("tag", tag)
            
           
            
            let suby = this.http.get(`${this.baseUrl}/uploads/getSuggestions`, {
                params: {
                  tag: tag
                },
                observe: 'response'
              }).subscribe((res):any => {
                try{
                  suby.unsubscribe()
                  let checkOut:any = res.body?.valueOf()
                  let rr = checkOut.response;
                  resolve(rr)
                }
                catch(err)
                {
                }
              }, error=>{
                reject(error)
              })
        }
        catch(err)
        {
            reject(err)
        }
    })
  }
}