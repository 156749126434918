import { createAction, props } from '@ngrx/store';

export interface singleEdit{
    id: string,
    fundingControl: string | null,
    scoreControl: string | null,
    formStatus: boolean,
    comment: string | null
}

export interface rankInfo{
    currentIndex: number,
    previousIndex: number
}

export const edit = createAction('[Seo List] Edit', props<{listItem:singleEdit}>());
export const create = createAction('[Seo List] Create', props<{content:any}>());
export const rankChange = createAction('[Seo List] Rank Order Changed Requested', props<{rankInfo:rankInfo}>());
export const rankChangeSuccess = createAction('[Seo List] Rank Order Changed Success');
export const rankChangeFail = createAction('[Seo List] Rank Order Changed Failure');
export const selectProposal = createAction('[Seo List] Seo Proposal Selected', props<{propUUID:string}>());
export const clearSeoList = createAction('[Seo List] Clear request');



export const reset = createAction('[Seo List] Reset');
export const apiCallSeo = createAction('[Seo List] Api call');
export const loadSeoList = createAction('[Seo List] Started api call to load')
export const loadSeoListSucess = createAction('[Seo List] Api load sucess', props<{seoList:[]}>())
export const loadSeoListFailure = createAction('[Seo List] Api load Failure', props<{error:string}>())
